import * as React from "react";
import {
  OfficeBuildingIcon,
  NewspaperIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Adventure from "../images/svgs/adventure_map.svg";
import Background from "../images/background.jpg";
import { StaticImage } from "gatsby-plugin-image"
import { H1 } from "../components/typography";

const supportLinks = [
  {
    name: "Press Inquiries",
    href: "mailto:alfie@getvessel.co.uk",
    description:
      "Want to setup an interview or ask us any questions, please get in touch",
    icon: NewspaperIcon,
    linkName: "Email us",
  },
  {
    name: "Building Vessel",
    href: "/learn",
    description:
      "Read our blog posts about how we built Vessel. The problems we came across and how we solved them",
    icon: OfficeBuildingIcon,
    linkName: "Building Vessel blog",
  },
  {
    name: "Partner with us",
    href: "mailto:alfie@getvessel.co.uk",
    description: "Interested in partnering with us? Send us a quick email",
    icon: UserGroupIcon,
    linkName: "Email us",
  },
];

const IndexPage = () => (
  <Layout
    title="What we're about - About us"
    description="At Vessel, we're trying to redefine what investing means. Find out more about our journey"
    canonical="about-vessel"
    siteName="Vessel"
  >
    <div className="pt-16 sm:pt-16 lg:pt-8 pb-12 lg:pb-48 lg:overflow-hidden">
      <div className="mx-auto max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
            <div className="lg:py-24">
              <H1 className="my-4">
                <span className="block">We&apos;re investing, together</span>
              </H1>
              <p className="text-base pt-4 text-gray-600 sm:text-xl lg:text-lg xl:text-xl">
                Our goal is to get the masses investing. Vessel was founded upon
                the belief that financial freedom is achievable for everyone. We
                aim to not only provide an investment platform, but also provide
                educational resources alongside.
              </p>
            </div>
          </div>
          <div className="mt-12 lg:m-0 relative">
            <div className="mx-auto max-w-md px-4 sm:px-6 lg:px-0">
              <img
                className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto mt-12"
                src={Adventure}
                alt="Yacht"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="bg-white">
      <div className="relative pb-32 bg-gray-800 max-w-7xl mx-auto rounded-xl">
        <div className="absolute inset-0 ">
          <StaticImage
            className="w-full h-full rounded-xl object-cover object-center"
            src="../images/background.jpg"
            layout="fullWidth"
            alt=""
          />
          <div
            className="absolute inset-0 bg-gray-400 rounded-xl"
            style={{ mixBlendMode: "multiply" }}
            aria-hidden="true"
          />
        </div>
        <div className="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
          <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-5xl lg:text-6xl">
            Becoming the vessel for your investments
          </h1>
          <p className="mt-6 max-w-3xl text-xl text-gray-300">
            If you&apos;ll pardon the pun
          </p>
        </div>
      </div>

      <section
        className="-mt-32 max-w-7xl mx-auto relative z-10 pb-32 px-4 sm:px-6 lg:px-8"
        aria-labelledby="contact-heading"
      >
        <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
          {supportLinks.map((link) => (
            <div
              key={link.name}
              className="flex flex-col bg-white rounded-2xl shadow-xl"
            >
              <div className="flex-1 relative pt-16 px-6 pb-8 md:px-8">
                <div className="absolute top-0 p-5 inline-block bg-blue-600 rounded-xl shadow-lg transform -translate-y-1/2">
                  <link.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </div>
                <h3 className="text-xl font-medium text-gray-900">
                  {link.name}
                </h3>
                <p className="mt-4 text-base text-gray-500">
                  {link.description}
                </p>
              </div>
              <div className="p-6 bg-gray-50 rounded-bl-2xl rounded-br-2xl md:px-8">
                {link.href.startsWith("mailto:")? <a href={link.href} className="text-base font-medium text-blue-700 hover:text-blue-600">{link.linkName}</a>
                :
                <Link
                  to={link.href}
                  className="text-base font-medium text-blue-700 hover:text-blue-600"
                >
                  {link.linkName}
                </Link>
                }
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  </Layout>
);

export default IndexPage;
